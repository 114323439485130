/**
 *  function  that cancel the scrolling in the page if the value is true
 * @param {boolean} turnOn
 */

export const cancel_scrolling = (turnOn) => {
  const htmlBody = document.getElementsByTagName("body")[0];
  if (turnOn) {
    htmlBody.style.overflow = "hidden";
  } else {
    htmlBody.style.overflow = "auto";
  }
};

/**
 * Convert client type to a readable text.
 * @param {number} clientType
 * @returns {string}
 */
export const getClientTypeText = (clientType) => {
  switch (clientType) {
    case 1:
      return "חברה פרטית";
    case 2:
      return "חברה ציבורית";
    case 3:
      return "עוסק מורשה";
    default:
      return "סוג לא ידוע";
  }
};
