import React, { Fragment, useEffect, useState } from "react";
import { Table, Button, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import "../clients.scss";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";
import { getClientTypeText } from "../../../utils/supportFunctions";

const ClientsTable = ({
  setIsOpen,
  handleShowModal,
  setClient,
  clients,
  fetchClients,
  totalPages,
  loading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [firstName] = useState(
    JSON.parse(localStorage.getItem("userData")).firstName || ""
  );

  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    fetchClients(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowClick = (clientId) => {
    navigate(`/dashboard/clients/${clientId}`);
  };

  return (
    <Fragment>
      {loading ? (
        <LoaderComponent />
      ) : clients?.length > 0 ? (
        <div className="custom-table-container">
          <div className="table-header">
            <span className="lead font-weight-bold">לקוחות רשומים</span>
            <Button
              className="table-btn"
              variant="primary"
              type="submit"
              onClick={() => setIsOpen(true)}
            >
              יצירת לקוח חדש
            </Button>
          </div>
          <Table responsive className="table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th className="font-weight-medium">שם הלקוח</th>
                <th className="font-weight-medium">סוג הלקוח</th>
                <th className="font-weight-medium">מזהה לקוח</th>
                <th className="font-weight-medium">דוא״ל הנה״ח</th>
              </tr>
            </thead>
            <tbody>
              {clients?.map((item, index) => (
                <tr
                  key={item._id}
                  className="table-row"
                  onClick={() => handleRowClick(item._id)} // Make row clickable
                  style={{ cursor: "pointer" }} // Show a pointer cursor on hover
                >
                  <td>{index + 1}</td>
                  <td>
                    <div className="d-flex gap-2 align-items-center align-self-center">
                      <img
                        src={item.profileImage || "default-image-path.jpg"} // Use a default image if no profile image is set
                        alt="Profile"
                        className="profile-image"
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          margin: 0,
                        }}
                      />
                      <div className="d-flex align-items-center">
                        {item.name}
                      </div>
                    </div>
                  </td>
                  <td>{getClientTypeText(item.clientType)}</td>
                  <td>{item.llsNumber}</td>
                  <td>{item.billingEmail}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {totalPages > 1 && (
            <Pagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          )}
        </div>
      ) : (
        <div className="custom-table-container">
          <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
            <div>
              <p className="lead text-darker font-weight-black text-center mb-0">
                שלום {firstName} 👋
              </p>
              <p className="text-muted text-center mb-4">
                לפני שמתחילים, צריך להוסיף לקוח אחד לפחות באמצעות הכפתור למטה
              </p>
            </div>
            <Button
              className="table-btn mb-2"
              variant="primary"
              type="submit"
              onClick={() => setIsOpen(true)}
            >
              הכפתור למטה
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ClientsTable;
