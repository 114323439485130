import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Table } from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import { getClientTypeText } from "../../utils/supportFunctions";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";

const Dashboard = () => {
  const [totalClients, setTotalClients] = useState([]);
  const [totalOrganizers, setTotalOrganizers] = useState([]);
  const [lastFiveOrganizers, setLastFiveOrganizers] = useState([]);
  const [lastFiveClients, setLastFiveClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboard();
  }, []);

  const fetchDashboard = async (page) => {
    setLoading(true);
    const token = getToken();

    if (!token) {
      console.error("No token found, please log in.");
      setLoading(false);
      return;
    }

    const dataObj = {
      request: `dashboard`,
    };

    try {
      const response = await fetchData(dataObj, "GET", token);
      setTotalClients(response.data.totalClients);
      setTotalOrganizers(response.data.totalOrganizers);
      setLastFiveOrganizers(response.data.lastFiveOrganizers);
      setLastFiveClients(response.data.lastFiveClients);
    } catch (error) {
      console.error("Error fetching activities:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getToken = () => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const { token } = JSON.parse(userData);
      return token;
    }
    return null;
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <Container fluid>
      <h2 className="font-weight-bold">לוח הבקרה</h2>
      <Row className="d-flex align-items-stretch">
        <Col md={3} className="d-flex">
          <Card className="mb-3 flex-fill">
            <Card.Body>
              <Card.Title>
                <span className="text-lg font-weight-medium">לקוחות</span>
              </Card.Title>
              <h1 className="font-weight-bold">{totalClients}</h1>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="d-flex">
          <Card className="mb-3 flex-fill">
            <Card.Body>
              <Card.Title>
                <span className="text-lg font-weight-medium">סדרנים</span>
              </Card.Title>
              <h1 className="font-weight-bold">{totalOrganizers}</h1>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="d-flex">
          <Card className="mb-3 flex-fill">
            <Card.Body>
              <Card.Title>
                <span className="text-lg font-weight-medium">
                  נסיעות פתוחות
                </span>
              </Card.Title>
              <h1 className="font-weight-bold">-</h1>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="d-flex">
          <Card className="mb-3 flex-fill">
            <Card.Body className="d-flex flex-column justify-content-between">
              <div>
                <Card.Title className="text-lg font-weight-medium">
                  פעילויות אחרונות
                </Card.Title>
                <Card.Text>צפה בכל היסטורית הפעולות במערכת</Card.Text>
              </div>
              <div className="d-flex justify-content-end text-white pt-4">
                <Button
                  className="table-btn"
                  variant="primary"
                  href="/dashboard/activities"
                >
                  צפה בכולם
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="d-flex align-items-stretch">
        <Col md={6} className="d-flex">
          <Card className="mb-4 flex-fill">
            <Card.Header className="font-weight-bold">
              חמשת הלקוחות האחרונים
            </Card.Header>
            <Card.Body>
              <Table responsive className="table-hover">
                <thead>
                  <tr>
                    <th className="font-weight-medium">שם הלקוח</th>
                    <th className="font-weight-medium">סוג הלקוח</th>
                    <th className="font-weight-medium">מזהה חברה</th>
                  </tr>
                </thead>
                <tbody>
                  {lastFiveClients?.map((client) => (
                    <tr
                      key={client.id}
                      onClick={() =>
                        (window.location.href = `/dashboard/clients/${client.id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>{client.name}</td>
                      <td>{getClientTypeText(client.clientType)}</td>
                      <td>{client.llsNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="d-flex">
          <Card className="mb-4 flex-fill">
            <Card.Header className="font-weight-bold">
              חמשת הסדרנים אחרונים
            </Card.Header>
            <Card.Body>
              <Table responsive className="table-hover">
                <thead>
                  <tr>
                    <th className="font-weight-medium">שם פרטי</th>
                    <th className="font-weight-medium">שם משפחה</th>
                    <th className="font-weight-medium">מספר נייד</th>
                    <th className="font-weight-medium">שייך ללקוח</th>
                  </tr>
                </thead>
                <tbody>
                  {lastFiveOrganizers?.map((organizer) => (
                    <tr
                      key={organizer._id}
                      onClick={() =>
                        (window.location.href = `/dashboard/clients/${organizer.client._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>{organizer.firstName}</td>
                      <td>{organizer.lastName}</td>
                      <td>{organizer.phoneNumber}</td>
                      <td>{organizer.client?.name || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
