import React from "react";
import { Nav } from "react-bootstrap";
import "./Sidemenu.scss";
import { NavLink } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import { BsFillPersonVcardFill } from "react-icons/bs";

const navItems = [
  {
    to: "/dashboard",
    icon: <MdSpaceDashboard />,
    label: "לוח הבקרה",
    end: true,
  },
  {
    to: "/dashboard/clients",
    icon: <BsFillPersonVcardFill />,
    label: "לקוחות",
  },
];

const SideMenu = () => {
  return (
    <Nav className="d-none d-md-block bg-light sidebar">
      {navItems.map((item, index) => (
        <Nav.Item key={index} className="nav-item">
          <NavLink
            to={item.to}
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            end={item.end}
          >
            <div className="sidebar__item">
              <div className="sidebar__item__icon">{item.icon}</div>
              <span>{item.label}</span>
            </div>
          </NavLink>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default SideMenu;
