import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { MdSpaceDashboard, MdComputer } from "react-icons/md";
import "./mobileTopMenu.scss";
import Logo from "../../assets/icons/logo.svg";

const navItems = [
  {
    to: "/dashboard",
    icon: <MdSpaceDashboard />,
    label: "לוח הבקרה",
    end: true,
  },
  {
    to: "/dashboard/clients",
    icon: <MdComputer />,
    label: "לקוחות",
  },
];

const MobileTopMenu = () => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      variant="dark"
      expand="lg"
      className="d-md-none navbar"
      sticky="top"
    >
      <Navbar.Brand as={Link} to="/dashboard">
        <img
          className="img-thumbnail mx-auto d-block mb-2 login__form__logo"
          src={Logo}
          alt="logo"
          style={{ width: "150px" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(!expanded)}
        className="custom-toggler"
      />
      <Navbar.Collapse id="basic-navbar-nav" in={expanded}>
        <Nav className="mr-auto">
          {navItems.map((item, index) => (
            <Nav.Link
              key={index}
              as={Link}
              to={item.to}
              onClick={() => setExpanded(false)}
              className={location.pathname === item.to ? "active" : ""}
            >
              {item.icon} {item.label}
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MobileTopMenu;
