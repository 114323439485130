import React from "react";
import { Button, Modal } from "react-bootstrap";

const AreYouSureModal = ({
  isAreYouSureOpen,
  closeAreYouSureModal,
  modalMessage,
  modalTitle,
  handleApprove,
}) => {
  return (
    <Modal show={isAreYouSureOpen}>
      <Modal.Header closeButton onHide={() => closeAreYouSureModal()}>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalMessage}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            closeAreYouSureModal();
          }}
        >
          ביטול
        </Button>
        <Button variant="primary" type="submit" onClick={() => handleApprove()}>
          אישור
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AreYouSureModal;
