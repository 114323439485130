import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";
import Dashboard from "../../pages/dashboard/dashboard";
import MobileTopMenu from "../../components/MobileTopMenu/MobileTopMenu";
import Settings from "../../pages/settings/settings";
import Clients from "../../pages/clients/clients";
import Organizers from "../../pages/organizers/organizers";
import "./dashboardLayout.scss";
import "../../sass/base/_base.scss";
import ClientDetails from "../../pages/clients/ClientDetails/ClientDetails";
import ActivitiesPage from "../../pages/ActivitiesPage/ActivitiesPage";

const DashboardLayout = () => {
  const location = useLocation();
  const isScreenPreview = location.pathname.includes("/clientss/");

  return (
    <div>
      {!isScreenPreview && <MobileTopMenu />}
      <Container fluid>
        <Row>
          {!isScreenPreview && (
            <Col md={2} className="d-none d-md-block">
              <SideMenu />
            </Col>
          )}
          <Col
            md={isScreenPreview ? 12 : 10}
            className={isScreenPreview ? "p-0 m-0" : "mt-3"}
          >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="organizers" element={<Organizers />} />
              <Route path="clients" element={<Clients />} />
              <Route path="activities" element={<ActivitiesPage />} />
              <Route path="/clients/:id" element={<ClientDetails />} />
              <Route path="settings" element={<Settings />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardLayout;
