import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchData } from "../../../utils/fetchData";
import { Card, Container, Row, Col, Button, Form } from "react-bootstrap";
import { BsCamera, BsPencilSquare, BsTrash } from "react-icons/bs";
import ToastComponent from "../../../components/Toast/Toast";
import AreYouSureModal from "../../../components/AreYouSureModal/AreYouSureModal";
import "./ClientDetails.scss";
import { getClientTypeText } from "../../../utils/supportFunctions";
import { CreateOrganizerModal } from "../CreateOrganizerModal";
import DangerZone from "./DangerZone";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";

const ClientDetails = () => {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [organizers, setOrganizers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOrganizerModalOpen, setIsOrganizerModalOpen] = useState(false);
  const [selectedOrganizer, setSelectedOrganizer] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [organizerToRemove, setOrganizerToRemove] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedClient, setEditedClient] = useState({});

  const fetchClientDetails = async () => {
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      const clientResponse = await fetchData(
        { request: `clients/${id}` },
        "GET",
        token
      );
      setClient(clientResponse.client);
      setEditedClient(clientResponse.client);

      const organizersResponse = await fetchData(
        { request: `clients/${id}/organizers` },
        "GET",
        token
      );
      setOrganizers(organizersResponse.organizers);
    } catch (error) {
      console.error("Error fetching client or organizers details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientDetails();
  }, [id]);

  const handleOpenOrganizerModal = (organizer = null) => {
    setSelectedOrganizer(organizer);
    setIsOrganizerModalOpen(true);
  };

  const handleRemoveOrganizer = async () => {
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      const response = await fetchData(
        { request: `organizers/${organizerToRemove._id}` },
        "DELETE",
        token
      );

      setOrganizers((prevOrganizers) =>
        prevOrganizers.filter(
          (organizer) => organizer._id !== organizerToRemove._id
        )
      );

      setToastMessage(response.message);
      setToastType("success");
      setShowToast(true);
    } catch (error) {
      console.error("Error removing organizer:", error);
      setToastMessage("לא ניתן להסיר סדרן");
      setToastType("danger");
      setShowToast(true);
    } finally {
      setLoading(false);
      setIsAreYouSureOpen(false);
    }
  };

  const handleShowAreYouSureModal = (organizer) => {
    setOrganizerToRemove(organizer);
    setIsAreYouSureOpen(true);
  };

  const closeAreYouSureModal = () => {
    setIsAreYouSureOpen(false);
    setOrganizerToRemove(null);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("profileImage", file);

    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      const response = await fetchData(
        { request: `clients/upload/${id}`, body: formData },
        "POST",
        token,
        true
      );

      await fetchClientDetails();
      setToastMessage(response.message);
      setToastType("success");
      setShowToast(true);
    } catch (error) {
      console.error("Error uploading image:", error);
      setToastMessage("Failed to upload image");
      setToastType("danger");
      setShowToast(true);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveChanges = async () => {
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      await fetchData(
        { request: `clients/${id}`, body: editedClient },
        "PUT",
        token
      );
      await fetchClientDetails();
      setIsEditing(false);
      setToastMessage("Client updated successfully");
      setToastType("success");
      setShowToast(true);
    } catch (error) {
      console.error("Error updating client details:", error);
      setToastMessage("Failed to update client details");
      setToastType("danger");
      setShowToast(true);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedClient(client);
  };

  const handleInputChange = (e) => {
    setEditedClient({ ...editedClient, [e.target.name]: e.target.value });
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center mt-5">
        <LoaderComponent />
      </Container>
    );
  }

  if (!client) {
    return (
      <Container className="d-flex justify-content-center align-items-center mt-5">
        <p>Client not found.</p>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Row>
        <Col md={8} className="mx-auto">
          <h2 className="mb-4 text-center font-weight-bold">פרטי לקוח</h2>
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div className="w-100">
                {isEditing ? (
                  <>
                    <span>שם החברה:</span>
                    <Form.Control
                      type="text"
                      name="name"
                      value={editedClient.name}
                      onChange={handleInputChange}
                      className="mb-2"
                    />
                    <span>סוג החברה:</span>
                    <Form.Select
                      name="clientType"
                      value={editedClient.clientType}
                      onChange={handleInputChange}
                      className="mb-2"
                    >
                      <option value={1}>חברה פרטית</option>
                      <option value={2}>חברה ציבורית</option>
                      <option value={3}>עוסק מורשה</option>
                    </Form.Select>
                    <span>מזהה החברה:</span>
                    <Form.Control
                      type="text"
                      name="llsNumber"
                      value={editedClient.llsNumber}
                      onChange={handleInputChange}
                      className="mb-2"
                    />
                    <span>מייל הנה״ח:</span>
                    <Form.Control
                      type="email"
                      name="billingEmail"
                      value={editedClient.billingEmail}
                      onChange={handleInputChange}
                      className="mb-2"
                    />
                    {isEditing && (
                      <>
                        <Button
                          variant="danger"
                          className="ms-2"
                          onClick={handleCancelEdit}
                        >
                          ביטול
                        </Button>
                        <Button
                          variant="success"
                          className="ms-2"
                          onClick={handleSaveChanges}
                        >
                          שמור
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="d-flex gap-2">
                      <Card.Title>
                        {client.name}
                        {!isEditing && (
                          <Button
                            className="ms-2"
                            variant="outline-secondary"
                            onClick={handleEditToggle}
                          >
                            <BsPencilSquare />
                          </Button>
                        )}
                      </Card.Title>
                    </div>

                    <div>
                      <Card.Text className="m-0 p-0">
                        סוג הלקוח: {getClientTypeText(client.clientType)}
                      </Card.Text>
                      <Card.Text className="m-0 p-0">
                        <strong>מזהה חברה:</strong> {client.llsNumber}
                      </Card.Text>
                      <Card.Text className="m-0 p-0">
                        <strong>מייל הנה״ח:</strong> {client.billingEmail}
                      </Card.Text>
                    </div>
                  </>
                )}
              </div>
              {!isEditing && (
                <div className="d-flex align-items-center">
                  {client.profileImage && (
                    <div className="profile-container">
                      <img
                        src={client.profileImage}
                        alt="Profile"
                        className="profile-image"
                      />
                      <Button
                        variant="light"
                        className="camera-icon"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        <BsCamera size={15} />
                      </Button>
                      <Form.Control
                        id="fileInput"
                        type="file"
                        onChange={handleImageUpload}
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                    </div>
                  )}
                </div>
              )}
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <Card.Title>סדרנים</Card.Title>
                <Button
                  className="table-btn"
                  variant="primary"
                  onClick={() => handleOpenOrganizerModal()}
                >
                  הוספת סדרן
                </Button>
              </div>
              {organizers.length > 0 ? (
                organizers.map((organizer, index) => (
                  <div key={organizer._id}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p className="m-0 p-0">
                          <strong className="font-weight-medium">
                            שם הסדרן:
                          </strong>{" "}
                          {organizer.firstName} {organizer.lastName}
                        </p>
                        <p>
                          <strong className="font-weight-medium">
                            מספר נייד:
                          </strong>{" "}
                          {organizer.phoneNumber}
                        </p>
                      </div>
                      <div className="d-flex justify-content-end align-items-center gap-1">
                        <Button
                          variant="outline-secondary d-flex align-items-center"
                          onClick={() => handleOpenOrganizerModal(organizer)}
                        >
                          <BsPencilSquare />
                          <span className="mx-1">עריכה</span>
                        </Button>
                        <Button
                          variant="outline-danger d-flex align-items-center"
                          onClick={() => handleShowAreYouSureModal(organizer)}
                        >
                          <BsTrash />
                          <span className="mx-1">מחיקה</span>
                        </Button>
                      </div>
                    </div>
                    {index < organizers.length - 1 && <hr />}
                  </div>
                ))
              ) : (
                <>
                  <p className="m-0 p-0">לא קיימים סדרנים ללקוח זה,</p>
                  <p>לחץ על הכפתור "הוספת סדרן" בשביל להוסיף אחד</p>
                </>
              )}
            </Card.Body>
          </Card>
          <hr className="m-4" />
          <DangerZone
            id={id}
            client={client}
            setToastMessage={setToastMessage}
            setShowToast={setShowToast}
            setToastType={setToastType}
            fetchClientDetails={fetchClientDetails}
          />
        </Col>
      </Row>

      <CreateOrganizerModal
        isOpen={isOrganizerModalOpen}
        setIsOpen={setIsOrganizerModalOpen}
        fetchOrganizers={fetchClientDetails}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
        setToastType={setToastType}
        organizer={selectedOrganizer}
        setOrganizer={setSelectedOrganizer}
        client={[client]}
        disableClientSelect={true}
      />

      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={closeAreYouSureModal}
        handleApprove={handleRemoveOrganizer}
        modalTitle="האם אתה בטוח?"
        modalMessage={`האם אתה בטוח שברצונך להסיר את הסדרן ${organizerToRemove?.firstName} ${organizerToRemove?.lastName}?`}
      />

      <ToastComponent
        type={toastType}
        message={toastMessage}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </Container>
  );
};

export default ClientDetails;
