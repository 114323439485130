import React, { useEffect, useState } from "react";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
import { fetchData } from "../../utils/fetchData";
import ToastComponent from "../../components/Toast/Toast";
import OrganizersTable from "./organizersTable/crganizersTable";
import { CreateOrganizerModal } from "./CreateOrganizerModal";

const Organizers = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [organizer, setOrganizer] = useState(null);

  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const [organizers, setOrganizers] = useState([]);
  const [clients, setClients] = useState([]); // State to store clients
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTable, setActiveTable] = useState("active");

  useEffect(() => {
    fetchActiveScreens();
    fetchClients(); // Fetch clients on component mount
  }, [activeTable]);

  // FETCHING ORGANIZERS FROM SERVER
  const fetchActiveScreens = async (page = 1) => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("userData")).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const params = { page, limit: 10 };
      const res = "organizers";
      const requestObject = {
        request: res,
      };
      const response = await fetchData(requestObject, "GET", token, params);
      setOrganizers(response?.organizers);
      setTotalPages(response?.totalPages);
      setCurrentPage(response?.currentPage);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // FETCHING CLIENTS FROM SERVER
  const fetchClients = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userData")).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestObject = {
        request: "clients",
      };
      const response = await fetchData(requestObject, "GET", token);
      setClients(response?.clients || []); // Set the fetched clients
    } catch (err) {
      console.error("Error fetching clients:", err.message);
      setError(err.message);
    }
  };

  // FUNCTION TO HANDLE WITH REMOVE BUTTON WHEN USER DELETING AN ORGANIZER
  const handleRemove = async () => {
    console.log("!!");
    console.log(organizer);
    console.log("!!");
    try {
      setLoading(true);
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestObject = {
        request: `organizers/${organizer._id}`,
      };
      const response = await fetchData(requestObject, "DELETE", token);
      setOrganizer(null);
      setToastType("success");
      setToastMessage(response.message);
      setShowToast(true);
      setIsAreYouSureOpen(false);
      fetchActiveScreens();
    } catch (err) {
      console.error(err.message);
      setOrganizer(null);
      setToastType("danger");
      setToastMessage(err.message);
      setShowToast(true);
      setIsAreYouSureOpen(false);
      fetchActiveScreens();
    } finally {
      setLoading(false);
    }
  };

  // FUNCTION TO SHOW ARE YOU SURE MODAL
  const handleShowModal = (type) => {
    setModalType(type);
    setIsAreYouSureOpen(true);
  };

  // FUNCTION TO CLOSE ARE YOU SURE MODAL
  const closeAreYouSureModal = () => {
    setIsAreYouSureOpen(false);
    setOrganizer(null);
  };

  return (
    <div>
      <h2 className="font-weight-bold">סדרנים</h2>
      <p className="p-0 m-0">ניהול כל הסדרנים, הוספה, הסרה, ועוד.</p>
      <p className="p-0 m-0 pb-3">
        מה זה אומר? כל סדרן שיוקם במערכת יוכל להתחבר למערכת ווילז.
      </p>

      <OrganizersTable
        setIsOpen={setIsOpen}
        handleShowModal={handleShowModal}
        setScreen={setOrganizer}
        fetchOrganizers={fetchActiveScreens}
        organizers={organizers}
        totalPages={totalPages}
        loading={loading}
      />

      <CreateOrganizerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fetchOrganizers={fetchActiveScreens}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
        setToastType={setToastType}
        organizer={organizer}
        setOrganizer={setOrganizer}
        clients={clients} // Pass clients to the modal
      />

      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={closeAreYouSureModal}
        handleApprove={handleRemove}
        setToastMessage={setToastMessage}
        setShowToast={setShowToast}
        setToastType={setToastType}
        modalTitle={"בטוח למחוק לך"}
        modalMessage={`כן, אני רוצה למחוק את ${organizer?.firstName} ${organizer?.lastName}`}
      />

      <ToastComponent
        type={toastType}
        message={toastMessage}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </div>
  );
};

export default Organizers;
