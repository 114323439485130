import { apiUrl } from "./baseValues";
import axios from "axios";

export async function fetchData(
  requestObject,
  method = "POST",
  token = null,
  params = {}
) {
  try {
    if (navigator.onLine) {
      const env = process.env.REACT_APP_ENV || "development";
      const apiEndpoint = apiUrl[env];
      const fullUrl = `${apiEndpoint}${requestObject.request}`;

      const config = {
        method: method,
        url: fullUrl,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Only set 'Content-Type' to 'application/json' if not uploading files
      if (method === "POST" || method === "PUT") {
        if (requestObject.body) {
          if (requestObject.body instanceof FormData) {
            // Let the browser set 'Content-Type' for FormData
            config.data = requestObject.body;
          } else {
            config.headers["Content-Type"] = "application/json";
            config.data = requestObject.body;
          }
        } else {
          config.headers["Content-Type"] = "application/json";
          config.data = requestObject;
        }
      } else if (method === "GET") {
        config.params = params;
      }

      const response = await axios(config);
      const data = response.data;
      if (data.rc === "rc_success") {
        return data;
      } else if (data.rc === "rc_token_expire") {
        console.log("rc_token_expire");
        return;
      } else {
        return data;
      }
    } else {
      console.warn("No internet connection.");
      throw new Error("No internet connection.");
    }
  } catch (error) {
    console.error("Error:", error);
    if (error.response && error.response.data) {
      throw new Error(error.response.data);
    } else {
      throw new Error("An unknown error occurred.");
    }
  }
}
