import React, { Fragment, useEffect, useState } from "react";
import { Table, Button, Pagination } from "react-bootstrap";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import "../../clients/clients.scss";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../utils/fetchData";

const OrganizersTable = ({
  setIsOpen,
  handleShowModal,
  setScreen,
  organizers,
  fetchOrganizers,
  totalPages,
  loading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numOfClients, setNumOfClients] = useState(0);
  const [userData] = useState(
    JSON.parse(localStorage.getItem("userData")) || null
  );
  const navigate = useNavigate();

  // GET CURRENT PAGE
  useEffect(() => {
    if (numOfClients?.length > 0) {
      fetchOrganizers(currentPage);
    }
  }, [currentPage]);

  // HANDLING WITH PAGES
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // FUNCTION TO DISPLAY THE NUMBER OF STATIONS THAT SCREEN WILL BE DISPLAYED ON
  const handleDisplayedStations = (station) => {
    const numberOfStations = station?.length;
    return numberOfStations;
  };

  useEffect(() => {
    fetchStations();
  }, []);

  //FUNCTION TO CHECK IF THERE ARE CLIENTS
  const fetchStations = async (page = 1) => {
    try {
      if (!userData.token) {
        throw new Error("User is not authenticated");
      }
      const requestObject = { request: "clients/count" };
      const response = await fetchData(requestObject, "GET", userData.token);
      setNumOfClients(response.clientsCount);
    } catch (err) {
      // Handle errors
    } finally {
      // Cleanup or finalize actions
    }
  };

  const displayTableState = () => {
    const states = [
      {
        title: "רק רגע ✋",
        subTitle: "לפני שמתחילים, צריך להוסיף עמדה אחת לפחות.",
      },
      {
        title: `מעולה ${userData.firstName} 🙌`,
        subTitle:
          "אנחנו רואים שהוספת כבר לקוח אחד לפחות, עכשיו אפשר להוסיף גם סדרנים.",
      },
    ];
    return (
      <div className="custom-table-container">
        <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
          <div key={`table_state_${states[numOfClients > 0 ? 1 : 0].title}`}>
            <p className="lead text-darker font-weight-black text-center mb-0">
              {states[numOfClients > 0 ? 1 : 0].title}
            </p>
            <p className="text-muted text-center mb-4">
              {states[numOfClients > 0 ? 1 : 0].subTitle}
            </p>
          </div>

          <Button
            className="table-btn mb-2"
            variant="primary"
            type="submit"
            onClick={
              numOfClients > 0
                ? () => setIsOpen(true) // Open the modal for adding an organizer
                : () => navigate("/dashboard/clients")
            }
          >
            {numOfClients > 0 ? "הוספת סדרן" : "מעבר לעמוד לקוחות"}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {loading ? (
        <LoaderComponent />
      ) : organizers?.length > 0 ? (
        <div className="custom-table-container">
          <div className="table-header">
            <div>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <div className="active_screen" />
                <span className="text-sm">
                  מסך פעיל - מסך שמוצג כרגע בעמדות כי הוא נמצא בתאריכים, הימים
                  והשעות שהוגדרו.
                </span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="pending_screen" />
                <span className="text-sm">
                  מסך מתוזמן - מסך שלא מוצג כרגע, אבל, מתוזמן למועד עתידי.
                </span>
              </div>
            </div>

            <Button
              className="table-btn"
              variant="primary"
              type="submit"
              onClick={() => setIsOpen(true)} // Open the modal
            >
              הוסף סדרן
            </Button>
          </div>

          <Table responsive className="custom-table">
            <thead>
              <tr>
                <th></th>
                <th>שם הסדרן</th>
                <th>שם הלקוח</th>
                <th>מספר נייד</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {organizers.map((item, index) => {
                let isExpired = item.status === "expired";
                return (
                  <tr key={item?._id}>
                    <td
                      className={classNames(
                        "justify-content-start" && isExpired
                          ? "text-decoration-line-through text-muted"
                          : ""
                      )}
                    >
                      {index + 1}
                    </td>
                    <td
                      className={classNames(
                        "justify-content-start" && isExpired
                          ? "text-decoration-line-through text-muted"
                          : ""
                      )}
                    >
                      <span>
                        {item.firstName} {item.lastName}
                      </span>
                    </td>
                    <td
                      className={classNames(
                        "justify-content-start" && isExpired
                          ? "text-decoration-line-through text-muted"
                          : ""
                      )}
                    >
                      <div className="d-flex align-items-center">
                        {item.status === "active" ? (
                          <div className="active_screen me-1"> </div>
                        ) : item.status === "pending" ? (
                          <div className="pending_screen me-1"> </div>
                        ) : null}
                        {item.client?.name}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        "justify-content-start" && isExpired
                          ? "text-decoration-line-through text-muted"
                          : ""
                      )}
                    >
                      {item.phoneNumber}
                    </td>

                    <td>
                      <div className="d-flex justify-content-end align-items-center gap-1">
                        <Button
                          variant="outline-secondary d-flex align-items-center"
                          onClick={() => {
                            setIsOpen(true);
                            setScreen(item);
                          }}
                        >
                          <BsPencilSquare />
                          <span className="mx-1">עריכה</span>
                        </Button>
                        <Button
                          variant="outline-danger d-flex align-items-center"
                          onClick={() => {
                            setScreen(item);
                            handleShowModal(1);
                          }}
                        >
                          <BsTrash />
                          <span className="mx-1">מחיקה</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {totalPages > 1 && (
            <Pagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          )}
        </div>
      ) : (
        displayTableState()
      )}
    </Fragment>
  );
};

export default OrganizersTable;
